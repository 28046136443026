import * as React from "react";
import { Link } from "gatsby";
import { Button, Row, Col, Container } from "react-bootstrap";

import * as styles from "./index.module.scss";

// markup
const NotFoundPage = () => {
  return (
    <section className={styles.page}>
      <Container className="py-5">
        <Row className="text-center justify-content-center align-items-center">
          <Col>
            <h1 className={styles.title}>404</h1>
            <h2 className="mb-5">Page Not Found</h2>
            <Button variant="primary" size="lg" as={Link} to="/">
              BACK HOME
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default NotFoundPage;
